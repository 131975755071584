.centered-block {
	display: block;
	margin: auto;
	float: none !important;
}

.text-muted {
	color: #FFFFFF;
}

.pointer {
	cursor: pointer;
}

.flex-center {
	display: flex;
	align-items: center;
}

.centered {
	display: flex;
    justify-content: center;
    align-items: center;
}

.hero {
	min-height: 600px;
}

.hero  img.figures {
	position: absolute;
	width: 30%;
	bottom: 0;
    overflow: hidden;
}

.hero  img.figures.right {
	right: -70px;
}

.hero  img.figures.left {
	left: 0;
}

footer.footer {
	position: relative;
}

footer.footer .palm {
	position: absolute;
	top: -250px;
	left: -15px;
	height: 250px;
	width: 200px;
}

footer.footer .van {
	pointer-events: none;
	position: absolute;
	height: 150px;
	width: 350px;
	top: -140px;
	right: -150px;
}

.container-wrapper {
	overflow-x: hidden;
}

.form-group.has-error .select2-selection {
	border-color: #FF0000 !important;
}

span.red {
	color: red;
}

.sidebar{
    will-change: min-height;
}

.sidebar__inner{
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

div.testimonial-item.blue {
	margin: 10px 0;
    padding: 4px;
    background-color: #fff;
    border-radius: 17px;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2)
}
div.testimonial-item.blue div {
	background-color: #0095DA;
	padding: 10px;
	padding-left: 20px;
	border-radius: 14px;
	overflow: hidden;
	min-height: 205px;
}
div.testimonial-item.blue div p {
	color: #fff;
}
div.testimonial-item.blue div p::before {
	content: '"';
	display: block;
	font-size: 30px;
	position: absolute;
	top: 22px;
	left: 22px;
	font-weight: bold;
}
div.testimonial-item.blue div p b {
	font-size: 30px;
	line-height: .2;
}
div.testimonial-item.blue div img {
	max-width: 150px;
	max-height: 100px;
	width: auto;
	float: right;
	margin-top: 10px;
}


div.paypal button {
	position: relative;
	overflow: hidden;
}
div.paypal #paypal-button {
	opacity: 0;
	position: absolute;
	top: -11px;
	left: -73px;
	width: 100%;
	height: 100%;
}

.form-group .has-error input.el-input__inner {
	border-color: #a94442;
	background-color: #fefbfa;
}
.form-group .has-error p {
	color: #a94442;
}

.team-wrapper .team-item .image.img-circle {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
.team-wrapper .team-item .image.img-circle::before {
	content: '';
	display: block;
	padding-bottom: 100%;
}
.el-message-box {
	max-width: calc(100% - 10px);
}

.navbar-primary .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'><path stroke='rgb(255, 255, 255' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-primary .navbar-toggler:focus {
	outline: none;
}

.card {
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.input-group-append {
	background-color: #F4F5F6;
}
.input-group-append .btn {
	box-shadow: none;
}
.input-group-append .btn i.fa {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
button.btn.btn-outline-secondary.disabled:focus-visible {
	outline: 2px solid -webkit-focus-ring-color;
}

button.btn.btn-outline-primary:hover {
	color: #FFFFFF;
	outline: initial;
}

button.btn.btn-outline-primary:focus {
	box-shadow: initial;
}

.d-inline-table {
	display: inline-table;
}

.modal {
	z-index: 4;
}
.modal-backdrop {
	z-index: 3;
}

.quote {
	display: inline-block;
    padding: 2em;
    margin: 0 1em;
    border-radius: 1em;
    background-color: #9acae373;
    border: 3px solid transparent;
	margin-bottom: 2em;
	cursor: pointer;
	text-align: center;
	max-width: 15em;
}

.quote.selected {
	border-color: #006699;
}

.additional-item {
	filter: brightness(0);
	transition: filter .4s;
}

.additional-item.selected {
	filter: brightness(1);
}

.price-tag {
	position: absolute;
	top: 2px;
	right: 17px;
	background-color: aliceblue;
	border-top-right-radius: 9px;
	padding: 0.7em 1em;
	font-size: 1.3em;
	border-bottom-left-radius: 9px;
}