/*------------------------------------------------------------------
* Project:        Tour Packer
* Author:         Crenoveative
* URL:            http://crenoveative.com
* Version:        1.0.0
* Created:        06/03/2016
* Last change:    06/03/2016
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
* Table of Content
* 1. Reset
* 2. Default Typography
* 3. Margin Sets
* 4. Padding Sets
* 5. Border
* 6. Global
* 7. Form
* 8. List
* 9. Layout
* 10. Timing - Transition
* 11. Gap for Raw
* 12. Typography
* 13. Background
* 14. Button
* 15. Section Title
* 16. Social Media
* 17. GridLex
* 18. Customized Flex Grid from FOundation
-------------------------------------------------------------------*/


/**
 * Reset
 */

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    zoom: 1;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

* {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
    font-size: 13px;
    line-height: 25px;
    font-family: 'Open Sans', sans-serif;
    color: #666;
    -webkit-font-smoothing: antialiased!important;
    background-color: #FFF;
    font-weight: 400;
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
}

img {
    -ms-interpolation-mode: bicubic;
}

object,
embed {
    max-width: 100%;
}


/**
 * Default Typography
 */

a,
a:visited,
a:focus,
a:active,
a:hover {
    text-decoration: none;
    outline: none;
}

a {
    transition: all 0.3s ease-out;
    color: #006699;
    font-weight: 600;
}

a:hover,
a:focus {
    text-decoration: none;
    transition: all 0.3s ease-out;
    color: #013D71;
}

::-moz-selection {
    color: #FFF;
    background: #339DFB;
}

::selection {
    color: #FFF;
    background: #339DFB;
}

::-moz-selection {
    color: #FFF;
    background: #DE261D;
}

strong {
    font-weight: 700 !important;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: 700;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    margin-top: 20px;
    margin-bottom: 8px;
    font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif;
    color: #006699;
    line-height: 1.4;
    text-rendering: optimizeLegibility
}

h1,
.h1 {
    font-size: 32px;
}

h2,
.h2 {
    font-size: 28px;
}

h3,
.h3 {
    font-size: 25px;
}

h4,
.h4 {
    font-size: 21px;
}

h5,
.h5 {
    font-size: 17px;
}

h6,
.h6 {
    font-size: 14px;
}

@media all and (max-width: 767px) {
    h1,
    .h1 {
        font-size: 29px;
        line-height: 40px;
    }
    h2,
    .h2 {
        font-size: 25px;
        line-height: 40px;
    }
    h3,
    .h3 {
        font-size: 22px;
        line-height: 32px;
    }
    h4,
    .h4 {
        font-size: 18px;
        line-height: 26px;
    }
    h5,
    .h5 {
        font-size: 16px;
        line-height: 24px;
    }
    h6,
    .h6 {
        font-size: 12px;
        line-height: 24px;
    }
}

p {
    margin-bottom: 15px;
}

p.lead {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 25px;
}

@media all and (max-width: 767px) {
    p.lead {
        font-size: 13px;
        line-height: 24px;
    }
    p {
        font-size: 12px;
    }
}

blockquote,
.blockquote {
    overflow: hidden;
    font-family: "Merriweather", "Georgia", Times New Roman, Times, serif;
    font-size: 20px;
    line-height: 40px;
    font-style: italic;
    background: #f5f5f5;
    padding: 32px;
    color: #777;
    font-weight: 300;
    margin: 30px 0;
}

hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 25px 0;
    width: 100%;
}


/**
 * Margin Sets
 */

.mr-1 {
    margin-right: 1px !important
}

.mr-3 {
    margin-right: 3px !important
}

.mr-5 {
    margin-right: 5px !important
}

.mr-10 {
    margin-right: 10px !important
}

.mr-15 {
    margin-right: 15px !important
}

.mr-20 {
    margin-right: 20px !important
}

.mr-25 {
    margin-right: 25px !important
}

.mr-30 {
    margin-right: 30px !important
}

.mr-40 {
    margin-right: 40px !important
}

.mr-50 {
    margin-right: 50px !important
}

.ml-1 {
    margin-left: 1px !important
}

.ml-3 {
    margin-left: 3px !important
}

.ml-5 {
    margin-left: 5px !important
}

.ml-10 {
    margin-left: 10px !important
}

.ml-15 {
    margin-left: 15px !important
}

.ml-20 {
    margin-left: 20px !important
}

.ml-25 {
    margin-left: 25px !important
}

.ml-30 {
    margin-left: 30px !important
}

.ml-40 {
    margin-left: 40px !important
}

.ml-50 {
    margin-left: 50px !important
}

.mt-1 {
    margin-top: 1px !important
}

.mt-3 {
    margin-top: 3px !important
}

.mt-5 {
    margin-top: 5px !important
}

.mt-10 {
    margin-top: 10px !important
}

.mt-15 {
    margin-top: 15px !important
}

.mt-20 {
    margin-top: 20px !important
}

.mt-25 {
    margin-top: 25px !important
}

.mt-30 {
    margin-top: 30px !important
}

.mt-40 {
    margin-top: 40px !important
}

.mt-50 {
    margin-top: 50px !important
}

.mt-70 {
    margin-top: 70px !important
}

.mt-80 {
    margin-top: 80px !important
}

.mt-90 {
    margin-top: 90px !important
}

.mb-1 {
    margin-bottom: 1px !important
}

.mb-5 {
    margin-bottom: 5px !important
}

.mb-10 {
    margin-bottom: 10px !important
}

.mb-15 {
    margin-bottom: 15px !important
}

.mb-16 {
    margin-bottom: 16px !important
}

.mb-20 {
    margin-bottom: 20px !important
}

.mb-25 {
    margin-bottom: 25px !important
}

.mb-30 {
    margin-bottom: 30px !important
}

.mb-40 {
    margin-bottom: 40px !important
}

.mb-50 {
    margin-bottom: 50px !important
}

.mb-60 {
    margin-bottom: 60px !important
}

.mb-70 {
    margin-bottom: 70px !important
}

.mb-80 {
    margin-bottom: 80px !important
}

.mv-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.mv-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mv-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.mv-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.mv-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mv-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.mv-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.mv-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}

.mh-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mh-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mh-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mh-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mh-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.mh-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.mh-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.mh-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
}

.margin-5 {
    margin: 5px !important;
}

.margin-10 {
    margin: 10px !important;
}

.margin-15 {
    margin: 15px !important;
}

.margin-20 {
    margin: 20px !important;
}

.margin-25 {
    margin: 25px !important;
}

.no-magin,
ul.no-margin,
.margin-0 {
    margin: 0 !important
}

.no-mt,
.mt-0 {
    margin-top: 0 !important
}

.no-mb,
.mb-0 {
    margin-bottom: 0 !important
}

.no-ml,
.ml-0 {
    margin-left: 0 !important
}

.no-mr,
.mr-0 {
    margin-right: 0 !important
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {
    .mb-10-sm {
        margin-bottom: 10px !important
    }
    .mb-15-sm {
        margin-bottom: 15px !important
    }
    .mb-20-sm {
        margin-bottom: 20px !important
    }
    .mb-25-sm {
        margin-bottom: 25px !important
    }
    .mb-30-sm {
        margin-bottom: 30px !important
    }
    .mb-40-sm {
        margin-bottom: 40px !important
    }
    .mb-50-sm {
        margin-bottom: 50px !important
    }
    .mt-10-sm {
        margin-top: 10px !important
    }
    .mt-15-sm {
        margin-top: 15px !important
    }
    .mt-20-sm {
        margin-top: 20px !important
    }
    .mt-25-sm {
        margin-top: 25px !important
    }
    .mt-30-sm {
        margin-top: 30px !important
    }
    .mt-40-sm {
        margin-top: 40px !important
    }
    .mt-50-sm {
        margin-top: 50px !important
    }
}

@media only screen and (max-width: 767px) {
    .mb-0-xs {
        margin-bottom: 0px !important
    }
    .mb-10-xs {
        margin-bottom: 10px !important
    }
    .mb-15-xs {
        margin-bottom: 15px !important
    }
    .mb-20-xs {
        margin-bottom: 20px !important
    }
    .mb-25-xs {
        margin-bottom: 25px !important
    }
    .mb-30-xs {
        margin-bottom: 30px !important
    }
    .mb-40-xs {
        margin-bottom: 40px !important
    }
    .mb-50-xs {
        margin-bottom: 50px !important
    }
    .mt-10-xs {
        margin-top: 10px !important
    }
    .mt-15-xs {
        margin-top: 15px !important
    }
    .mt-20-xs {
        margin-top: 20px !important
    }
    .mt-25-xs {
        margin-top: 25px !important
    }
    .mt-30-xs {
        margin-top: 30px !important
    }
    .mt-40-xs {
        margin-top: 40px !important
    }
    .mt-50-xs {
        margin-top: 50px !important
    }
}

@media (max-width: 479px) {
    .mb-0-xss {
        margin-bottom: 0px !important
    }
    .mb-10-xss {
        margin-bottom: 10px !important
    }
    .mb-15-xss {
        margin-bottom: 15px !important
    }
    .mb-20-xss {
        margin-bottom: 20px !important
    }
    .mb-25-xss {
        margin-bottom: 25px !important
    }
    .mb-30-xss {
        margin-bottom: 30px !important
    }
    .mb-40-xss {
        margin-bottom: 40px !important
    }
    .mb-50-xss {
        margin-bottom: 50px !important
    }
}


/**
 * Padding Sets
 */

.pr-5 {
    padding-right: 5px !important
}

.pr-10 {
    padding-right: 10px !important
}

.pr-15 {
    padding-right: 15px !important
}

.pr-20 {
    padding-right: 20px !important
}

.pr-30 {
    padding-right: 30px !important
}

.pr-40 {
    padding-right: 40px !important
}

.pr-50 {
    padding-right: 50px !important
}

.pr-0,
.no-pr {
    padding-right: 0 !important
}

.pl-5 {
    padding-left: 5px !important
}

.pl-10 {
    padding-left: 10px !important
}

.pl-15 {
    padding-left: 15px !important
}

.pl-20 {
    padding-left: 20px !important
}

.pl-30 {
    padding-left: 30px !important
}

.pl-40 {
    padding-left: 40px !important
}

.pl-50 {
    padding-left: 50px !important
}

.pl-0,
.no-pl {
    padding-left: 0 !important
}

.pt-5 {
    padding-top: 5px !important
}

.pt-10 {
    padding-top: 10px !important
}

.pt-15 {
    padding-top: 15px !important
}

.pt-20 {
    padding-top: 20px !important
}

.pt-25 {
    padding-top: 25px !important
}

.pt-30 {
    padding-top: 30px !important
}

.pt-40 {
    padding-top: 40px !important
}

.pt-50 {
    padding-top: 50px !important
}

.pt-60 {
    padding-top: 60px !important
}

.pt-0,
.no-pt {
    padding-top: 0px !important
}

.pb-5 {
    padding-bottom: 5px !important
}

.pb-10 {
    padding-bottom: 10px !important
}

.pb-15 {
    padding-bottom: 15px !important
}

.pb-20 {
    padding-bottom: 20px !important
}

.pb-30 {
    padding-bottom: 30px !important
}

.pb-40 {
    padding-bottom: 40px !important
}

.pb-50 {
    padding-bottom: 50px !important
}

.pb-60 {
    padding-bottom: 60px !important
}

.pb-70 {
    padding-bottom: 70px !important
}

.pb-80 {
    padding-bottom: 80px !important
}

.pb-0,
.no-pb {
    padding-bottom: 0 !important
}

.pv-5 {
    padding-top: 5px !important;
    padding-bottom: 5px!important;
}

.pv-10 {
    padding-top: 10px !important;
    padding-bottom: 10px!important;
}

.pv-15 {
    padding-top: 15px !important;
    padding-bottom: 15px!important;
}

.pv-20 {
    padding-top: 20px !important;
    padding-bottom: 20px!important;
}

.pv-30 {
    padding-top: 30px !important;
    padding-bottom: 30px!important;
}

.pv-40 {
    padding-top: 40px !important;
    padding-bottom: 40px!important;
}

.pv-50 {
    padding-top: 50px!important;
    padding-bottom: 50px!important;
}

.pv-60 {
    padding-top: 60px!important;
    padding-bottom: 60px!important;
}

.pv-70 {
    padding-top: 70px!important;
    padding-bottom: 70px!important;
}

.no-pv,
.pv-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ph-5 {
    padding-left: 5px !important;
    padding-right: 5px!important;
}

.ph-10 {
    padding-left: 10px !important;
    padding-right: 10px!important;
}

.ph-15 {
    padding-left: 15px !important;
    padding-right: 15px!important;
}

.ph-20 {
    padding-left: 20px !important;
    padding-right: 20px!important;
}

.ph-30 {
    padding-left: 30px !important;
    padding-right: 30px!important;
}

.ph-40 {
    padding-left: 40px !important;
    padding-right: 40px!important;
}

.ph-50 {
    padding-left: 50px!important;
    padding-right: 50px!important;
}

.ph-60 {
    padding-left: 60px!important;
    padding-right: 60px!important;
}

.ph-70 {
    padding-left: 70px!important;
    padding-right: 70px!important;
}

.no-ph,
.ph-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.padding-5 {
    padding: 5px !important
}

.padding-10 {
    padding: 10px !important
}

.padding-15 {
    padding: 15px !important
}

.padding-20 {
    padding: 20px !important
}

.padding-30 {
    padding: 30px !important
}

.no-padding,
.padding-0 {
    padding: 0 !important
}


/**
 * Border
 */

.border {
    border: 1px solid #E3E3E3;
}

.border-bottom {
    border-bottom: 1px solid #E3E3E3;
}

.border-top {
    border-top: 1px solid #E3E3E3;
}

.border-right {
    border-right: 1px solid #E3E3E3;
}

.border-left {
    border-left: 1px solid #E3E3E3;
}

.no-border {
    border: 0 !important;
}


/**
 * Global
 */

.absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}

.relative {
    position: relative;
}

.clear {
    clear: both;
    width: 100%;
    overflow: hidden;
}

.sm-clear {
    display: none;
}

.map-wrapper img {
    max-width: none;
}

.overflow-x-hidden,
.hidden-x {
    overflow-x: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out 0s;
}

.map-wrapper img,
.widget-map img {
    max-width: none !important;
}

.absolute-in-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {
    .pull-left-sm {
        float: left !important;
    }
}

@media only screen and (max-width: 767px) {
    .pull-left-xs {
        float: left !important;
    }
    .clear-xs {
        clear: both;
        width: 100%;
        overflow: hidden;
    }
}

@media (max-width: 479px) {
    .xss-hidden,
    .hidden-xss {
        display: none;
    }
    .block-xss {
        display: block !important;
    }
    .pull-left-xss {
        float: left !important;
    }
    .clear-xss {
        clear: both;
        width: 100%;
        overflow: hidden;
    }
}


/**
 * Form
 */

label {
    font-weight: 600;
}

.form-control {
    border-radius: 3px;
    box-shadow: none;
    height: 36px;
}

.form-control:focus,
.form-control:active {
    outline: 0;
    box-shadow: none;
    border-color: #013D71;
}

.input-group .form-control {
    height: 37px;
}

.form-control.width-auto {
    width: auto;
}

.form-icon {
    position: relative;
}

.form-icon i {
    position: absolute;
    bottom: 11px;
    left: 12px;
    z-index: 1;
    line-height: 1;
    color: #999;
}

.form-icon .select2-container .select2-selection--multiple {
    padding-left: 30px;
}


/**
 * List
 */

ul.list-with-icon li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    line-height: 25px;
}

ul.list-with-icon li i {
    position: absolute;
    top: 5px;
    left: 0;
}

ul.list-with-icon.with-heading li {
    padding-left: 23px;
    margin-bottom: 15px;
}

ul.list-with-icon.with-heading li:last-child {
    margin-bottom: 0;
}

ul.list-with-icon.with-heading li i {
    top: 1px;
    left: 0;
}

ul.list-with-icon.col-3 {
    margin-left: -10px;
    margin-right: -10px;
}

ul.list-with-icon.col-3 li {
    padding: 0 20px;
    padding-left: 30px;
    width: 33.333333%;
    float: left;
}

ul.list-with-icon.col-3 li i {
    left: 10px;
}

ul.border-bottom {
    border-bottom: 0;
}

ul.border-bottom li {
    border-bottom: 1px solid #CCC;
    padding-bottom: 10px;
}

ul.border-bottom.dotted li {
    border-bottom: 1px dotted #CCC;
}

ul.border-bottom li:last-child {
    border-bottom: 0;
}

ul.help-list li {
    border-bottom: 1px solid #E3E3E3;
    line-height: 1.2;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

ul.help-list li:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
    ul.list-with-icon.col-3 li {
        width: 100%;
    }
}

@media (max-width: 479px) {}


/**
 * Layout
 */

.square {
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
}

.page-content-wrapper {
    min-height: calc(100vh - 595px);
}

.square .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.square .content .text-center {
    max-height: 80%;
    width: auto;
}

.container-wrapper {
    padding-top: 110px;
}

.content-wrapper {
    padding: 60px 0;
}

.main-wrapper {
    background: #FFF;
}

.container {
    position: relative;
}

.width-50 {
    width: 50%;
}

.width-25 {
    width: 25%;
}

.width-20 {
    width: 20%;
}

.width-10 {
    width: 10%;
}

ul.list-col li {
    display: inline-block;
    position: relative;
}

ul.list-col li:not(:last-child) {
    margin-right: 50px;
}

ul.list-col li:not(:last-child)::after {
    content: "";
    width: 1px;
    background: #999;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -25px;
}

@media (max-width: 991px) {
    .container {
        width: 100%;
    }
    .container-wrapper {
        padding-top: 48px;
    }
    .sidebar-line {
        right: 33.3333%;
    }
    .content-wrapper {
        padding-right: 0;
    }
    .sidebar-wrapper {
        padding-top: 0;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .container-wrapper {
        padding-top: 0;
    }
    .sidebar-line {
        display: none;
    }
    .xs-overflow {
        overflow: hidden;
    }
    ul.list-col li {
        width: 100%;
        display: block;
        height: auto;
        margin: 0 0 15px;
    }
    ul.list-col li:not(:last-child)::after {
        display: none;
    }
}

@media (max-width: 479px) {}

@media (min-width: 768px) {
    .col-sm-height,
    .content-sm-height {
        display: table-cell;
        float: none!important
    }

    .clear {
        height: 90px !important;
    }
}


/**
 * Timing - Transition
 */

.delay_0-5 {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.delay_1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.delay_1-5 {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s
}

.delay_2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.delay_2-5 {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s
}

.delay_3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

.delay_3-5 {
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s
}

.delay_4 {
    -webkit-animation-delay: 4s;
    animation-delay: 4s
}

.delay_4-5 {
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s
}

.delay_5 {
    -webkit-animation-delay: 5s;
    animation-delay: 5s
}


/**
 * Gap for Raw
 */

.row.gap-50 {
    margin-right: -25px;
    margin-left: -25px;
}

.gap-50 .col-sm-1,
.gap-50 .col-sm-2,
.gap-50 .col-sm-3,
.gap-50 .col-sm-4,
.gap-50 .col-sm-5,
.gap-50 .col-sm-6,
.gap-50 .col-sm-7,
.gap-50 .col-sm-8,
.gap-50 .col-sm-9,
.gap-50 .col-sm-10,
.gap-50 .col-sm-11,
.gap-50 .col-sm-12,
.gap-50 .col-md-1,
.gap-50 .col-md-2,
.gap-50 .col-md-3,
.gap-50 .col-md-4,
.gap-50 .col-md-5,
.gap-50 .col-md-6,
.gap-50 .col-md-7,
.gap-50 .col-md-8,
.gap-50 .col-md-9,
.gap-50 .col-md-10,
.gap-50 .col-md-11,
.gap-50 .col-md-12 {
    padding-right: 25px;
    padding-left: 25px;
}

.row.gap-40 {
    margin-right: -20px;
    margin-left: -20px;
}

.gap-40 .col-sm-1,
.gap-40 .col-sm-2,
.gap-40 .col-sm-3,
.gap-40 .col-sm-4,
.gap-40 .col-sm-5,
.gap-40 .col-sm-6,
.gap-40 .col-sm-7,
.gap-40 .col-sm-8,
.gap-40 .col-sm-9,
.gap-40 .col-sm-10,
.gap-40 .col-sm-11,
.gap-40 .col-sm-12,
.gap-40 .col-md-1,
.gap-40 .col-md-2,
.gap-40 .col-md-3,
.gap-40 .col-md-4,
.gap-40 .col-md-5,
.gap-40 .col-md-6,
.gap-40 .col-md-7,
.gap-40 .col-md-8,
.gap-40 .col-md-9,
.gap-40 .col-md-10,
.gap-40 .col-md-11,
.gap-40 .col-md-12 {
    padding-right: 20px;
    padding-left: 20px;
}

.row.gap-25 {
    margin-right: -13px;
    margin-left: -12px;
}

.gap-25 .col-sm-1,
.gap-25 .col-sm-2,
.gap-25 .col-sm-3,
.gap-25 .col-sm-4,
.gap-25 .col-sm-5,
.gap-25 .col-sm-6,
.gap-25 .col-sm-7,
.gap-25 .col-sm-8,
.gap-25 .col-sm-9,
.gap-25 .col-sm-10,
.gap-25 .col-sm-11,
.gap-25 .col-sm-12,
.gap-25 .col-md-1,
.gap-25 .col-md-2,
.gap-25 .col-md-3,
.gap-25 .col-md-4,
.gap-25 .col-md-5,
.gap-25 .col-md-6,
.gap-25 .col-md-7,
.gap-25 .col-md-8,
.gap-25 .col-md-9,
.gap-25 .col-md-10,
.gap-25 .col-md-11,
.gap-25 .col-md-12 {
    padding-right: 13px;
    padding-left: 12px;
}

.row.gap-20 {
    margin-right: -10px;
    margin-left: -10px;
}

.gap-20 .col-sm-1,
.gap-20 .col-sm-2,
.gap-20 .col-sm-3,
.gap-20 .col-sm-4,
.gap-20 .col-sm-5,
.gap-20 .col-sm-6,
.gap-20 .col-sm-7,
.gap-20 .col-sm-8,
.gap-20 .col-sm-9,
.gap-20 .col-sm-10,
.gap-20 .col-sm-11,
.gap-20 .col-sm-12,
.gap-20 .col-md-1,
.gap-20 .col-md-2,
.gap-20 .col-md-3,
.gap-20 .col-md-4,
.gap-20 .col-md-5,
.gap-20 .col-md-6,
.gap-20 .col-md-7,
.gap-20 .col-md-8,
.gap-20 .col-md-9,
.gap-20 .col-md-10,
.gap-20 .col-md-11,
.gap-20 .col-md-12 {
    padding-right: 10px;
    padding-left: 10px;
}

.row.gap-15 {
    margin-right: -8px;
    margin-left: -7px;
}

.gap-15 .col-sm-1,
.gap-15 .col-sm-2,
.gap-15 .col-sm-3,
.gap-15 .col-sm-4,
.gap-15 .col-sm-5,
.gap-15 .col-sm-6,
.gap-15 .col-sm-7,
.gap-15 .col-sm-8,
.gap-15 .col-sm-9,
.gap-15 .col-sm-10,
.gap-15 .col-sm-11,
.gap-15 .col-sm-12,
.gap-15 .col-md-1,
.gap-15 .col-md-2,
.gap-15 .col-md-3,
.gap-15 .col-md-4,
.gap-15 .col-md-5,
.gap-15 .col-md-6,
.gap-15 .col-md-7,
.gap-15 .col-md-8,
.gap-15 .col-md-9,
.gap-15 .col-md-10,
.gap-15 .col-md-11,
.gap-15 .col-md-12 {
    padding-right: 8px;
    padding-left: 7px;
}

.row.gap-10 {
    margin-right: -5px;
    margin-left: -5px;
}

.gap-10 .col-sm-1,
.gap-10 .col-sm-2,
.gap-10 .col-sm-3,
.gap-10 .col-sm-4,
.gap-10 .col-sm-5,
.gap-10 .col-sm-6,
.gap-10 .col-sm-7,
.gap-10 .col-sm-8,
.gap-10 .col-sm-9,
.gap-10 .col-sm-10,
.gap-10 .col-sm-11,
.gap-10 .col-sm-12,
.gap-10 .col-md-1,
.gap-10 .col-md-2,
.gap-10 .col-md-3,
.gap-10 .col-md-4,
.gap-10 .col-md-5,
.gap-10 .col-md-6,
.gap-10 .col-md-7,
.gap-10 .col-md-8,
.gap-10 .col-md-9,
.gap-10 .col-md-10,
.gap-10 .col-md-11,
.gap-10 .col-md-12 {
    padding-right: 5px;
    padding-left: 5px;
}

.row.gap-5 {
    margin-right: -3px;
    margin-left: -2px;
}

.gap-5 .col-sm-1,
.gap-5 .col-sm-2,
.gap-5 .col-sm-3,
.gap-5 .col-sm-4,
.gap-5 .col-sm-5,
.gap-5 .col-sm-6,
.gap-5 .col-sm-7,
.gap-5 .col-sm-8,
.gap-5 .col-sm-9,
.gap-5 .col-sm-10,
.gap-5 .col-sm-11,
.gap-5 .col-sm-12,
.gap-5 .col-md-1,
.gap-5 .col-md-2,
.gap-5 .col-md-3,
.gap-5 .col-md-4,
.gap-5 .col-md-5,
.gap-5 .col-md-6,
.gap-5 .col-md-7,
.gap-5 .col-md-8,
.gap-5 .col-md-9,
.gap-5 .col-md-10,
.gap-5 .col-md-11,
.gap-5 .col-md-12 {
    padding-right: 3px;
    padding-left: 2px;
}

.row.gap-2 {
    margin-right: -1px;
    margin-left: -1px;
}

.gap-2 .col-sm-1,
.gap-2 .col-sm-2,
.gap-2 .col-sm-3,
.gap-2 .col-sm-4,
.gap-2 .col-sm-5,
.gap-2 .col-sm-6,
.gap-2 .col-sm-7,
.gap-2 .col-sm-8,
.gap-2 .col-sm-9,
.gap-2 .col-sm-10,
.gap-2 .col-sm-11,
.gap-2 .col-sm-12,
.gap-2 .col-md-1,
.gap-2 .col-md-2,
.gap-2 .col-md-3,
.gap-2 .col-md-4,
.gap-2 .col-md-5,
.gap-2 .col-md-6,
.gap-2 .col-md-7,
.gap-2 .col-md-8,
.gap-2 .col-md-9,
.gap-2 .col-md-10,
.gap-2 .col-md-11,
.gap-2 .col-md-12 {
    padding-right: 1px;
    padding-left: 1px;
}

.row.gap-1 {
    margin-right: -1px;
    margin-left: 0px;
}

.gap-1 .col-sm-1,
.gap-1 .col-sm-2,
.gap-1 .col-sm-3,
.gap-1 .col-sm-4,
.gap-1 .col-sm-5,
.gap-1 .col-sm-6,
.gap-1 .col-sm-7,
.gap-1 .col-sm-8,
.gap-1 .col-sm-9,
.gap-1 .col-sm-10,
.gap-1 .col-sm-11,
.gap-1 .col-sm-12,
.gap-1 .col-md-1,
.gap-1 .col-md-2,
.gap-1 .col-md-3,
.gap-1 .col-md-4,
.gap-1 .col-md-5,
.gap-1 .col-md-6,
.gap-1 .col-md-7,
.gap-1 .col-md-8,
.gap-1 .col-md-9,
.gap-1 .col-md-10,
.gap-1 .col-md-11,
.gap-1 .col-md-12 {
    padding-right: 1px;
    padding-left: 0;
}

.row.gap-0,
.row.no-gap {
    margin-right: 0;
    margin-left: 0;
}

.gap-0 .col-sm-1,
.gap-0 .col-sm-2,
.gap-0 .col-sm-3,
.gap-0 .col-sm-4,
.gap-0 .col-sm-5,
.gap-0 .col-sm-6,
.gap-0 .col-sm-7,
.gap-0 .col-sm-8,
.gap-0 .col-sm-9,
.gap-0 .col-sm-10,
.gap-0 .col-sm-11,
.gap-0 .col-sm-12,
.no-gap .col-sm-1,
.no-gap .col-sm-2,
.no-gap .col-sm-3,
.no-gap .col-sm-4,
.no-gap .col-sm-5,
.no-gap .col-sm-6,
.no-gap .col-sm-7,
.no-gap .col-sm-8,
.no-gap .col-sm-9,
.no-gap .col-sm-10,
.no-gap .col-sm-11,
.no-gap .col-sm-12,
.gap-0 .col-md-1,
.gap-0 .col-md-2,
.gap-0 .col-md-3,
.gap-0 .col-md-4,
.gap-0 .col-md-5,
.gap-0 .col-md-6,
.gap-0 .col-md-7,
.gap-0 .col-md-8,
.gap-0 .col-md-9,
.gap-0 .col-md-10,
.gap-0 .col-md-11,
.gap-0 .col-md-12,
.no-gap .col-md-1,
.no-gap .col-md-2,
.no-gap .col-md-3,
.no-gap .col-md-4,
.no-gap .col-md-5,
.no-gap .col-md-6,
.no-gap .col-md-7,
.no-gap .col-md-8,
.no-gap .col-md-9,
.no-gap .col-md-10,
.no-gap .col-md-11,
.no-gap .col-md-12 {
    padding-right: 0;
    padding-left: 0;
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {
    .row.sm-gap-20 {
        margin-right: -10px;
        margin-left: -10px;
    }
    .sm-gap-20 .col-sm-1,
    .sm-gap-20 .col-sm-2,
    .sm-gap-20 .col-sm-3,
    .sm-gap-20 .col-sm-4,
    .sm-gap-20 .col-sm-5,
    .sm-gap-20 .col-sm-6,
    .sm-gap-20 .col-sm-7,
    .sm-gap-20 .col-sm-8,
    .sm-gap-20 .col-sm-9,
    .sm-gap-20 .col-sm-10,
    .sm-gap-20 .col-sm-11,
    .sm-gap-20 .col-sm-12 {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .row.xs-gap-20 {
        margin-right: -10px;
        margin-left: -10px;
    }
    .xs-gap-20 .col-md-1,
    .xs-gap-20 .col-md-2,
    .xs-gap-20 .col-md-3,
    .xs-gap-20 .col-md-4,
    .xs-gap-20 .col-md-5,
    .xs-gap-20 .col-md-6,
    .xs-gap-20 .col-md-7,
    .xs-gap-20 .col-md-8,
    .xs-gap-20 .col-md-9,
    .xs-gap-20 .col-md-10,
    .xs-gap-20 .col-md-11,
    .xs-gap-20 .col-md-12 {
        padding-right: 10px;
        padding-left: 10px;
    }
    .row.xs-gap-15 {
        margin-right: -8px;
        margin-left: -7px;
    }
    .xs-gap-15 .col-md-1,
    .xs-gap-15 .col-md-2,
    .xs-gap-15 .col-md-3,
    .xs-gap-15 .col-md-4,
    .xs-gap-15 .col-md-5,
    .xs-gap-15 .col-md-6,
    .xs-gap-15 .col-md-7,
    .xs-gap-15 .col-md-8,
    .xs-gap-15 .col-md-9,
    .xs-gap-15 .col-md-10,
    .xs-gap-15 .col-md-11,
    .xs-gap-15 .col-md-12 {
        padding-right: 8px;
        padding-left: 7px;
    }
    .row.gap-10 {
        margin-right: -5px;
        margin-left: -5px;
    }
    .gap-10 .col-md-1,
    .gap-10 .col-md-2,
    .gap-10 .col-md-3,
    .gap-10 .col-md-4,
    .gap-10 .col-md-5,
    .gap-10 .col-md-6,
    .gap-10 .col-md-7,
    .gap-10 .col-md-8,
    .gap-10 .col-md-9,
    .gap-10 .col-md-10,
    .gap-10 .col-md-11,
    .gap-10 .col-md-12 {
        padding-right: 5px;
        padding-left: 5px;
    }
    .row.gap-5 {
        margin-right: -3px;
        margin-left: -2px;
    }
    .gap-5 .col-md-1,
    .gap-5 .col-md-2,
    .gap-5 .col-md-3,
    .gap-5 .col-md-4,
    .gap-5 .col-md-5,
    .gap-5 .col-md-6,
    .gap-5 .col-md-7,
    .gap-5 .col-md-8,
    .gap-5 .col-md-9,
    .gap-5 .col-md-10,
    .gap-5 .col-md-11,
    .gap-5 .col-md-12 {
        padding-right: 3px;
        padding-left: 2px;
    }
}

@media (max-width: 479px) {
    .col-xss-12 {
        width: 100%;
    }
    .col-xss-7 {
        width: 58.33333333%;
    }
    .col-xss-6 {
        width: 50%;
    }
    .col-xss-5 {
        width: 41.66666667%;
    }
    .col-xss-4 {
        width: 33.33333333%;
    }
    .col-xss-3 {
        width: 25%;
    }
}


/**
 * Typography
 */

.font10 {
    font-size: 10px !important;
}

.font12 {
    font-size: 12px !important;
}

.font13 {
    font-size: 13px !important;
}

.font14 {
    font-size: 14px !important;
}

.font15 {
    font-size: 15px !important;
}

.font16 {
    font-size: 16px !important;
}

.font18 {
    font-size: 18px !important;
}

.font20 {
    font-size: 20px !important;
}

.font22 {
    font-size: 22px !important;
}

.font24 {
    font-size: 24px !important;
}

.font26 {
    font-size: 26px !important;
}

.font300 {
    font-weight: 300 !important;
}

.font400 {
    font-weight: 400 !important;
}

.font600 {
    font-weight: 600 !important;
}

.font700 {
    font-weight: 700 !important;
}

.font800 {
    font-weight: 800 !important;
}

.font900 {
    font-weight: 900 !important;
}

.line10 {
    line-height: 10px !important;
}

.line12 {
    line-height: 12px !important;
}

.line14 {
    line-height: 14px !important;
}

.line16 {
    line-height: 16px !important;
}

.line18 {
    line-height: 18px !important;
}

.line20 {
    line-height: 20px !important;
}

.line22 {
    line-height: 22px !important;
}

.line24 {
    line-height: 24px !important;
}

.line26 {
    line-height: 26px !important;
}

.line28 {
    line-height: 28px !important;
}

.line30 {
    line-height: 30px !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-primary {
    color: #006699 !important;
}

.text-info {
    color: #00ABDF !important;
}

.text-success {
    color: #54D169 !important;
}

.text-warning {
    color: #FF8E01 !important;
}

.text-danger {
    color: #DB0B23 !important;
}

.text-white {
    color: #FFF !important;
}

.text-light {
    color: #999 !important;
}

.link-info {
    color: #00ABDF !important;
}

.link-info:hover {
    color: #0092BE !important;
}

.link-success {
    color: #68E030 !important;
}

.link-success:hover {
    color: #5CC72A !important;
}

.link-warning {
    color: #FFC537 !important;
}

.link-warning:hover {
    color: #E6B332 !important;
}

.link-danger {
    color: #FF3755 !important;
}

.link-danger:hover {
    color: #E6324D !important;
}

.uppercase {
    text-transform: uppercase !important;
}

a.link-alt {
    color: #AB9F9D;
}

a.link-alt:hover {
    color: #F04F38;
}

.font-italic {
    font-style: italic !important;
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {
    .text-center-sm {
        text-align: center !important;
    }
    .text-left-sm {
        text-align: left !important;
    }
}

@media only screen and (max-width: 767px) {
    .text-left-xs {
        text-align: left !important;
    }
}

@media (max-width: 479px) {
    .text-left-xss {
        text-align: left !important;
    }
}


/**
 * Background
 */

.bg-primary {
    background: #006699 !important;
    color: #FFF !important;
}

.bg-info {
    background: #8056B6 !important;
    color: #FFF !important;
}

.bg-success {
    background: #2FEB87 !important;
    color: #FFF !important;
}

.bg-warning {
    background: #FFBE33 !important;
    color: #FFF !important;
}

.bg-danger {
    background: #FF3366 !important;
    color: #FFF !important;
}

.bg-light {
    background: #f1f0f0 !important;
}

.bg-light-2 {
    background: #F2F2F2 !important;
}

.bg-dark {
    background: #3F4443 !important;
    color: #FFF;
}

.bg-white {
    background: #FFF !important;
}

.bg-primary h1,
.bg-primary h2,
.bg-primary h3,
.bg-primary h4,
.bg-primary h5,
.bg-primary h6,
.bg-info h1,
.bg-info h2,
.bg-info h3,
.bg-info h4,
.bg-info h5,
.bg-info h6,
.bg-success h1,
.bg-success h2,
.bg-success h3,
.bg-success h4,
.bg-success h5,
.bg-success h6,
.bg-warning h1,
.bg-warning h2,
.bg-warning h3,
.bg-warning h4,
.bg-warning h5,
.bg-warning h6,
.bg-danger h1,
.bg-danger h2,
.bg-danger h3,
.bg-danger h4,
.bg-danger h5,
.bg-danger h6,
.bg-color-02 h1,
.bg-color-02 h2,
.bg-color-02 h3,
.bg-color-02 h4,
.bg-color-02 h5,
.bg-color-02 h6,
.bg-bg-color-03 h1,
.bg-bg-color-03 h2,
.bg-bg-color-03 h3,
.bg-bg-color-03 h4,
.bg-bg-color-03 h5,
.bg-bg-color-03 h6 {
    color: #FFF !important;
}

.bg-primary a,
.bg-info a,
.bg-success a,
.bg-danger a {
    color: #FFF;
}

.bg-primary a:hover,
.bg-info a:hover,
.bg-success a:hover,
.bg-danger a:hover {
    text-decoration: underline;
}

.bg-img,
.bg-image,
.image-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


/**
 * Button
 */

.btn {
    border-radius: 3px;
    transition: all .3s;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 1;
    padding: 11px 20px;
    border-width: 2px;
}

.btn:active,
.btn.active {
    box-shadow: none;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn:active:focus {
    box-shadow: none;
    outline: none;
}

.btn-sm {
    padding: 8px 12px 7px;
    font-size: 10px;
    font-weight: 600;
}

.btn-default {
    color: #555;
    background-color: #F3F3F3;
    border-color: #F3F3F3;
}

.btn-default:hover {
    background-color: #EBEBEB;
    border-color: #EBEBEB;
}

.btn-primary {
    background: #006699;
    border-color: #006699;
    color: #FFF !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
    background: #013D71;
    border-color: #013D71;
}

.btn-info {
    background: #2E8ED7;
    border-color: #2E8ED7;
    color: #FFF !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
    background: #4477B2;
    border-color: #4477B2;
}

.btn-success {
    background: #358D44;
    border-color: #358D44;
    color: #FFF !important;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
    background: #52B545;
    border-color: #52B545;
}

.btn-warning {
    background: #D60D45;
    border-color: #D60D45;
    color: #FFF !important;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
    background: #EDC868;
    border-color: #EDC868;
}

.btn-danger {
    background: #D60D45;
    border-color: #D60D45;
    color: #FFF !important;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
    background: #F50C27;
    border-color: #F50C27;
}

.btn-magenta {
    background: #eb2690;
    border-color: #eb2690;
    color: #FFF !important;
}

.btn-magenta:hover,
.btn-magenta:focus,
.btn-magenta.focus {
    background: #da0d7b;
    border-color: #da0d7b;
}
.btn-primary.btn-inverse,
.btn-info.btn-inverse,
.btn-success.btn-inverse,
.btn-warning.btn-inverse,
.btn-danger.btn-inverse {
    background: #FFF;
}

.btn-primary.btn-inverse {
    color: #006699 !important;
    border-color: #006699 !important;
}

.btn-info.btn-inverse {
    color: #2E8ED7 !important;
}

.btn-success.btn-inverse {
    color: #68E030 !important;
}

.btn-warning.btn-inverse {
    color: #D60D45 !important;
}

.btn-danger.btn-inverse {
    color: #D60D45 !important;
}

.btn-primary.btn-inverse:hover {
    color: #FFF !important;
    background: #006699;
}

.btn-info.btn-inverse:hover {
    color: #FFF !important;
    background: #287CBD;
}

.btn-success.btn-inverse:hover {
    color: #FFF !important;
    background: #5CC72A;
}

.btn-warning.btn-inverse:hover {
    color: #FFF !important;
    background: #D60D45;
}

.btn-danger.btn-inverse:hover {
    color: #FFF !important;
    background: #E6324D;
}

.btn-lg {
    padding-top: 15px;
    padding-bottom: 12px;
}

.btn-lg.btn-icon {
    padding-right: 66px;
}

.btn-lg.btn-icon .icon {
    width: 46px;
    padding-top: 13px;
    font-size: 12px;
}

.btn-link {
    color: #006699;
    font-weight: 600;
    padding: 0;
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 1px;
    line-height: 1.2;
    border: 0;
    display: inline-block;
    margin: 0;
    margin-top: -1px;
}

.input-group .input-group-btn .btn {
    line-height: 1.42857143;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
}


/**
 * Section Title
 */

.section-title {
    margin: 0 0 35px;
    text-align: center;
}

.section-title h2,
.section-title h3,
.section-title h4 {
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
}

.section-title h4 {
    margin-bottom: -10px;
}

.section-title p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 25px 0 0;
    color: #777;
}

h1.heading,
h2.heading,
h3.heading,
h4.heading,
h5.heading,
h6.heading {
    color: #333;
    line-height: 1;
    margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
    .section-title p {
        font-size: 15px;
        line-height: 21px;
        margin: 15px 0 0;
    }
}

@media (max-width: 479px) {}


/**
 * Social Media
 */

.social-media {
    margin-bottom: 20px
}

.social-media a {
    font-size: 15px;
    line-height: 15px;
    color: #636363;
    text-decoration: none;
    margin-right: 20px;
    transition: all .3s
}

.social-media a:last-child {
    margin-right: 0
}

.social-media a.adobe-reader:hover {
    color: #a42222!important
}

.social-media a.amazon:hover {
    color: #fe9608!important
}

.social-media a.android:hover {
    color: #a4c639!important
}

.social-media a.apple:hover {
    color: #d6d7cf!important
}

.social-media a.badoo:hover {
    color: #f17834!important
}

.social-media a.behance:hover {
    color: #1e69e1!important
}

.social-media a.blogger:hover {
    color: #f26c37!important
}

.social-media a.blueetooth:hover {
    color: #0a3d91!important
}

.social-media a.chrome:hover {
    color: #ecc31d!important
}

.social-media a.css:hover {
    color: #14a0dd!important
}

.social-media a.deezer:hover {
    color: #ff0090!important
}

.social-media a.delicious:hover {
    color: #4088da!important
}

.social-media a.deviantart:hover {
    color: #b6ca2a!important
}

.social-media a.digg:hover {
    color: #006094!important
}

.social-media a.dribble:hover {
    color: #f26798!important
}

.social-media a.dropbox:hover {
    color: #007de4!important
}

.social-media a.drupal:hover {
    color: #3677ad!important
}

.social-media a.email:hover {
    color: #000000!important
}

.social-media a.facebook:hover {
    color: #3b5998!important
}

.social-media a.firefox:hover {
    color: #3194cb!important
}

.social-media a.flickr:hover {
    color: #0062dd!important
}

.social-media a.google:hover {
    color: #c63427!important
}

.social-media a.html:hover {
    color: #f06129!important
}

.social-media a.instagram:hover {
    color: #a07459!important
}

.social-media a.internet-explorer:hover {
    color: #20bcee!important
}

.social-media a.itunes:hover {
    color: #169bf0!important
}

.social-media a.java:hover {
    color: #e66e00!important
}

.social-media a.joomla:hover {
    color: #5aa526!important
}

.social-media a.last-fm:hover {
    color: #f73600!important
}

.social-media a.linkedin:hover {
    color: #0077b5!important
}

.social-media a.linux:hover {
    color: #000000!important
}

.social-media a.myspace:hover {
    color: #003399!important
}

.social-media a.opera:hover {
    color: #c10d0c!important
}

.social-media a.outlook:hover {
    color: #0073c6!important
}

.social-media a.paypal:hover {
    color: #193866!important
}

.social-media a.picasa:hover {
    color: #8b689f!important
}

.social-media a.pinterest:hover {
    color: #f71819!important
}

.social-media a.reddit:hover {
    color: #ff4500!important
}

.social-media a.rss:hover {
    color: #fb7629!important
}

.social-media a.safari:hover {
    color: #00a9ec!important
}

.social-media a.share:hover {
    color: #010101!important
}

.social-media a.skype:hover {
    color: #00abeb!important
}

.social-media a.soundcloud:hover {
    color: #ff3b00!important
}

.social-media a.spotify:hover {
    color: #9ac600!important
}

.social-media a.steam:hover {
    color: #020202!important
}

.social-media a.tumblr:hover {
    color: #34465d!important
}

.social-media a.twitter:hover {
    color: #5ea9dd!important
}

.social-media a.utorent:hover {
    color: #269036!important
}

.social-media a.vimeo:hover {
    color: #1ca7cc!important
}

.social-media a.vine:hover {
    color: #02c594!important
}

.social-media a.vk:hover {
    color: #4c75a3!important
}

.social-media a.weibo:hover {
    color: #d52b2b!important
}

.social-media a.weixin:hover {
    color: #f03c48!important
}

.social-media a.whatsapp:hover {
    color: #11ae09!important
}

.social-media a.wikipedia:hover {
    color: #363636!important
}

.social-media a.windows:hover {
    color: #00abf3!important
}

.social-media a.word:hover {
    color: #2a5699!important
}

.social-media a.wordpress:hover {
    color: #464646!important
}

.social-media a.xing:hover {
    color: #005d5e!important
}

.social-media a.yahoo:hover {
    color: #44029a!important
}

.social-media a.youtube:hover {
    color: #e22d24!important
}


/**
 * GridLex
 * http://gridlex.devlint.fr/index.html
 */

[class*=GridLex-grid] {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin: 0 -.5rem
}

.GridLex-col,
[class*=GridLex-col-] {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding: 0 .5rem 1rem
}

.GridLex-col.gap-10,
[class*=GridLex-col-].gap-10 {
    padding-left: 5px;
    padding-right: 5px;
}

.GridLex-col.gap-20,
[class*=GridLex-col-].gap-20 {
    padding-left: 10px;
    padding-right: 10px;
}

.GridLex-col {
    flex: 1 1 0
}

.GridLex-grid.GridLex-col,
.GridLex-grid[class*=GridLex-col-] {
    margin: 0;
    padding: 0
}

[class*=GridLex-grid-][class*=-noGutter] {
    margin: 0
}

[class*=GridLex-grid-][class*=-noGutter]>[class*=GridLex-col] {
    padding: 0
}

[class*=GridLex-grid-][class*=-center] {
    justify-content: center
}

[class*=GridLex-grid-][class*=-right] {
    justify-content: flex-end;
    align-self: flex-end;
    margin-left: auto
}

[class*=GridLex-grid-][class*=-top] {
    align-items: flex-start
}

[class*=GridLex-grid-][class*=-middle] {
    align-items: center
}

[class*=GridLex-grid-][class*=-bottom] {
    align-items: flex-end
}

[class*=GridLex-grid-][class*=-reverse] {
    flex-direction: row-reverse
}

[class*=GridLex-grid-][class*=-column] {
    flex-direction: column
}

[class*=GridLex-grid-][class*=-column-reverse] {
    flex-direction: column-reverse
}

[class*=GridLex-grid-][class*=-spaceBetween] {
    justify-content: space-between
}

[class*=GridLex-grid-][class*=-spaceAround] {
    justify-content: space-around
}

[class*=GridLex-grid-][class*=-equalHeight]>[class*=GridLex-col] {
    display: flex
}

[class*=GridLex-col-][class*=-top] {
    align-self: flex-start
}

[class*=GridLex-col-][class*=-middle] {
    align-self: center
}

[class*=GridLex-col-][class*=-bottom] {
    align-self: flex-end
}

[class*=GridLex-col-][class*=-first] {
    order: -1
}

[class*=GridLex-col-][class*=-last] {
    order: 1
}

[class*=GridLex-grid-1]>[class*=GridLex-col] {
    flex-basis: 100%;
    max-width: 100%
}

[class*=GridLex-grid-2]>[class*=GridLex-col] {
    flex-basis: 50%;
    max-width: 50%
}

[class*=GridLex-grid-3]>[class*=GridLex-col] {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
}

[class*=GridLex-grid-4]>[class*=GridLex-col] {
    flex-basis: 25%;
    max-width: 25%
}

[class*=GridLex-grid-5]>[class*=GridLex-col] {
    flex-basis: 20%;
    max-width: 20%
}

[class*=GridLex-grid-6]>[class*=GridLex-col] {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%
}

[class*=GridLex-grid-7]>[class*=GridLex-col] {
    flex-basis: 14.28571429%;
    max-width: 14.28571429%
}

[class*=GridLex-grid-8]>[class*=GridLex-col] {
    flex-basis: 12.5%;
    max-width: 12.5%
}

[class*=GridLex-grid-9]>[class*=GridLex-col] {
    flex-basis: 11.11111111%;
    max-width: 11.11111111%
}

[class*=GridLex-grid-10]>[class*=GridLex-col] {
    flex-basis: 10%;
    max-width: 10%
}

[class*=GridLex-grid-11]>[class*=GridLex-col] {
    flex-basis: 9.09090909%;
    max-width: 9.09090909%
}

[class*=GridLex-grid-12]>[class*=GridLex-col] {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%
}

@media screen and (max-width:80em) {
    [class*=_lg-1]>[class*=GridLex-col] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=_lg-2]>[class*=GridLex-col] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=_lg-3]>[class*=GridLex-col] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=_lg-4]>[class*=GridLex-col] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=_lg-5]>[class*=GridLex-col] {
        flex-basis: 20%;
        max-width: 20%
    }
    [class*=_lg-6]>[class*=GridLex-col] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=_lg-7]>[class*=GridLex-col] {
        flex-basis: 14.28571429%;
        max-width: 14.28571429%
    }
    [class*=_lg-8]>[class*=GridLex-col] {
        flex-basis: 12.5%;
        max-width: 12.5%
    }
    [class*=_lg-9]>[class*=GridLex-col] {
        flex-basis: 11.11111111%;
        max-width: 11.11111111%
    }
    [class*=_lg-10]>[class*=GridLex-col] {
        flex-basis: 10%;
        max-width: 10%
    }
    [class*=_lg-11]>[class*=GridLex-col] {
        flex-basis: 9.09090909%;
        max-width: 9.09090909%
    }
    [class*=_lg-12]>[class*=GridLex-col] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
}

@media screen and (max-width:64em) {
    [class*=_md-1]>[class*=GridLex-col] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=_md-2]>[class*=GridLex-col] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=_md-3]>[class*=GridLex-col] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=_md-4]>[class*=GridLex-col] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=_md-5]>[class*=GridLex-col] {
        flex-basis: 20%;
        max-width: 20%
    }
    [class*=_md-6]>[class*=GridLex-col] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=_md-7]>[class*=GridLex-col] {
        flex-basis: 14.28571429%;
        max-width: 14.28571429%
    }
    [class*=_md-8]>[class*=GridLex-col] {
        flex-basis: 12.5%;
        max-width: 12.5%
    }
    [class*=_md-9]>[class*=GridLex-col] {
        flex-basis: 11.11111111%;
        max-width: 11.11111111%
    }
    [class*=_md-10]>[class*=GridLex-col] {
        flex-basis: 10%;
        max-width: 10%
    }
    [class*=_md-11]>[class*=GridLex-col] {
        flex-basis: 9.09090909%;
        max-width: 9.09090909%
    }
    [class*=_md-12]>[class*=GridLex-col] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
}

@media screen and (max-width:48em) {
    [class*=_sm-1]>[class*=col] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=_sm-2]>[class*=col] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=_sm-3]>[class*=col] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=_sm-4]>[class*=col] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=_sm-5]>[class*=col] {
        flex-basis: 20%;
        max-width: 20%
    }
    [class*=_sm-6]>[class*=col] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=_sm-7]>[class*=col] {
        flex-basis: 14.28571429%;
        max-width: 14.28571429%
    }
    [class*=_sm-8]>[class*=col] {
        flex-basis: 12.5%;
        max-width: 12.5%
    }
    [class*=_sm-9]>[class*=col] {
        flex-basis: 11.11111111%;
        max-width: 11.11111111%
    }
    [class*=_sm-10]>[class*=col] {
        flex-basis: 10%;
        max-width: 10%
    }
    [class*=_sm-11]>[class*=col] {
        flex-basis: 9.09090909%;
        max-width: 9.09090909%
    }
    [class*=_sm-12]>[class*=col] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
}

@media screen and (max-width:35.5em) {
    [class*=_xs-1]>[class*=GridLex-col] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=_xs-2]>[class*=GridLex-col] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=_xs-3]>[class*=GridLex-col] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=_xs-4]>[class*=GridLex-col] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=_xs-5]>[class*=GridLex-col] {
        flex-basis: 20%;
        max-width: 20%
    }
    [class*=_xs-6]>[class*=GridLex-col] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=_xs-7]>[class*=GridLex-col] {
        flex-basis: 14.28571429%;
        max-width: 14.28571429%
    }
    [class*=_xs-8]>[class*=GridLex-col] {
        flex-basis: 12.5%;
        max-width: 12.5%
    }
    [class*=_xs-9]>[class*=GridLex-col] {
        flex-basis: 11.11111111%;
        max-width: 11.11111111%
    }
    [class*=_xs-10]>[class*=GridLex-col] {
        flex-basis: 10%;
        max-width: 10%
    }
    [class*=_xs-11]>[class*=GridLex-col] {
        flex-basis: 9.09090909%;
        max-width: 9.09090909%
    }
    [class*=_xs-12]>[class*=GridLex-col] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
}

[class*=GridLex-grid]>[class*=GridLex-col-1] {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%
}

[class*=GridLex-grid]>[class*=GridLex-col-2] {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%
}

[class*=GridLex-grid]>[class*=GridLex-col-3] {
    flex-basis: 25%;
    max-width: 25%
}

[class*=GridLex-grid]>[class*=GridLex-col-4] {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
}

[class*=GridLex-grid]>[class*=GridLex-col-5] {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%
}

[class*=GridLex-grid]>[class*=GridLex-col-6] {
    flex-basis: 50%;
    max-width: 50%
}

[class*=GridLex-grid]>[class*=GridLex-col-7] {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%
}

[class*=GridLex-grid]>[class*=GridLex-col-8] {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%
}

[class*=GridLex-grid]>[class*=GridLex-col-9] {
    flex-basis: 75%;
    max-width: 75%
}

[class*=GridLex-grid]>[class*=GridLex-col-10] {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%
}

[class*=GridLex-grid]>[class*=GridLex-col-11] {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%
}

[class*=GridLex-grid]>[class*=GridLex-col-12] {
    flex-basis: 100%;
    max-width: 100%
}

[class*=GridLex-grid]>[push-left*=off-0] {
    margin-left: 0
}

[class*=GridLex-grid]>[push-left*=off-1] {
    margin-left: 8.33333333%
}

[class*=GridLex-grid]>[push-left*=off-2] {
    margin-left: 16.66666667%
}

[class*=GridLex-grid]>[push-left*=off-3] {
    margin-left: 25%
}

[class*=GridLex-grid]>[push-left*=off-4] {
    margin-left: 33.33333333%
}

[class*=GridLex-grid]>[push-left*=off-5] {
    margin-left: 41.66666667%
}

[class*=GridLex-grid]>[push-left*=off-6] {
    margin-left: 50%
}

[class*=GridLex-grid]>[push-left*=off-7] {
    margin-left: 58.33333333%
}

[class*=GridLex-grid]>[push-left*=off-8] {
    margin-left: 66.66666667%
}

[class*=GridLex-grid]>[push-left*=off-9] {
    margin-left: 75%
}

[class*=GridLex-grid]>[push-left*=off-10] {
    margin-left: 83.33333333%
}

[class*=GridLex-grid]>[push-left*=off-11] {
    margin-left: 91.66666667%
}

[class*=GridLex-grid]>[push-right*=off-0] {
    margin-right: 0
}

[class*=GridLex-grid]>[push-right*=off-1] {
    margin-right: 8.33333333%
}

[class*=GridLex-grid]>[push-right*=off-2] {
    margin-right: 16.66666667%
}

[class*=GridLex-grid]>[push-right*=off-3] {
    margin-right: 25%
}

[class*=GridLex-grid]>[push-right*=off-4] {
    margin-right: 33.33333333%
}

[class*=GridLex-grid]>[push-right*=off-5] {
    margin-right: 41.66666667%
}

[class*=GridLex-grid]>[push-right*=off-6] {
    margin-right: 50%
}

[class*=GridLex-grid]>[push-right*=off-7] {
    margin-right: 58.33333333%
}

[class*=GridLex-grid]>[push-right*=off-8] {
    margin-right: 66.66666667%
}

[class*=GridLex-grid]>[push-right*=off-9] {
    margin-right: 75%
}

[class*=GridLex-grid]>[push-right*=off-10] {
    margin-right: 83.33333333%
}

[class*=GridLex-grid]>[push-right*=off-11] {
    margin-right: 91.66666667%
}

@media screen and (max-width:80em) {
    [class*=GridLex-grid]>[class*=_lg-1] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
    [class*=GridLex-grid]>[class*=_lg-2] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=GridLex-grid]>[class*=_lg-3] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=GridLex-grid]>[class*=_lg-4] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=GridLex-grid]>[class*=_lg-5] {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }
    [class*=GridLex-grid]>[class*=_lg-6] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=GridLex-grid]>[class*=_lg-7] {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }
    [class*=GridLex-grid]>[class*=_lg-8] {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }
    [class*=GridLex-grid]>[class*=_lg-9] {
        flex-basis: 75%;
        max-width: 75%
    }
    [class*=GridLex-grid]>[class*=_lg-10] {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }
    [class*=GridLex-grid]>[class*=_lg-11] {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }
    [class*=GridLex-grid]>[class*=_lg-12] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=GridLex-grid]>[push-left*=_lg-0] {
        margin-left: 0
    }
    [class*=GridLex-grid]>[push-left*=_lg-1] {
        margin-left: 8.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_lg-2] {
        margin-left: 16.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_lg-3] {
        margin-left: 25%
    }
    [class*=GridLex-grid]>[push-left*=_lg-4] {
        margin-left: 33.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_lg-5] {
        margin-left: 41.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_lg-6] {
        margin-left: 50%
    }
    [class*=GridLex-grid]>[push-left*=_lg-7] {
        margin-left: 58.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_lg-8] {
        margin-left: 66.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_lg-9] {
        margin-left: 75%
    }
    [class*=GridLex-grid]>[push-left*=_lg-10] {
        margin-left: 83.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_lg-11] {
        margin-left: 91.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_lg-0] {
        margin-right: 0
    }
    [class*=GridLex-grid]>[push-right*=_lg-1] {
        margin-right: 8.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_lg-2] {
        margin-right: 16.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_lg-3] {
        margin-right: 25%
    }
    [class*=GridLex-grid]>[push-right*=_lg-4] {
        margin-right: 33.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_lg-5] {
        margin-right: 41.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_lg-6] {
        margin-right: 50%
    }
    [class*=GridLex-grid]>[push-right*=_lg-7] {
        margin-right: 58.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_lg-8] {
        margin-right: 66.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_lg-9] {
        margin-right: 75%
    }
    [class*=GridLex-grid]>[push-right*=_lg-10] {
        margin-right: 83.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_lg-11] {
        margin-right: 91.66666667%
    }
}

@media screen and (max-width:64em) {
    [class*=GridLex-grid]>[class*=_md-1] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
    [class*=GridLex-grid]>[class*=_md-2] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=GridLex-grid]>[class*=_md-3] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=GridLex-grid]>[class*=_md-4] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=GridLex-grid]>[class*=_md-5] {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }
    [class*=GridLex-grid]>[class*=_md-6] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=GridLex-grid]>[class*=_md-7] {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }
    [class*=GridLex-grid]>[class*=_md-8] {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }
    [class*=GridLex-grid]>[class*=_md-9] {
        flex-basis: 75%;
        max-width: 75%
    }
    [class*=GridLex-grid]>[class*=_md-10] {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }
    [class*=GridLex-grid]>[class*=_md-11] {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }
    [class*=GridLex-grid]>[class*=_md-12] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=GridLex-grid]>[push-left*=_md-0] {
        margin-left: 0
    }
    [class*=GridLex-grid]>[push-left*=_md-1] {
        margin-left: 8.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_md-2] {
        margin-left: 16.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_md-3] {
        margin-left: 25%
    }
    [class*=GridLex-grid]>[push-left*=_md-4] {
        margin-left: 33.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_md-5] {
        margin-left: 41.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_md-6] {
        margin-left: 50%
    }
    [class*=GridLex-grid]>[push-left*=_md-7] {
        margin-left: 58.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_md-8] {
        margin-left: 66.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_md-9] {
        margin-left: 75%
    }
    [class*=GridLex-grid]>[push-left*=_md-10] {
        margin-left: 83.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_md-11] {
        margin-left: 91.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_md-0] {
        margin-right: 0
    }
    [class*=GridLex-grid]>[push-right*=_md-1] {
        margin-right: 8.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_md-2] {
        margin-right: 16.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_md-3] {
        margin-right: 25%
    }
    [class*=GridLex-grid]>[push-right*=_md-4] {
        margin-right: 33.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_md-5] {
        margin-right: 41.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_md-6] {
        margin-right: 50%
    }
    [class*=GridLex-grid]>[push-right*=_md-7] {
        margin-right: 58.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_md-8] {
        margin-right: 66.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_md-9] {
        margin-right: 75%
    }
    [class*=GridLex-grid]>[push-right*=_md-10] {
        margin-right: 83.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_md-11] {
        margin-right: 91.66666667%
    }
}

@media only screen and (max-width: 1199px) {
    [class*=GridLex-grid]>[class*=_mdd-1] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
    [class*=GridLex-grid]>[class*=_mdd-2] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=GridLex-grid]>[class*=_mdd-3] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=GridLex-grid]>[class*=_mdd-4] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=GridLex-grid]>[class*=_mdd-5] {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }
    [class*=GridLex-grid]>[class*=_mdd-6] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=GridLex-grid]>[class*=_mdd-7] {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }
    [class*=GridLex-grid]>[class*=_mdd-8] {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }
    [class*=GridLex-grid]>[class*=_mdd-9] {
        flex-basis: 75%;
        max-width: 75%
    }
    [class*=GridLex-grid]>[class*=_mdd-10] {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }
    [class*=GridLex-grid]>[class*=_mdd-11] {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }
    [class*=GridLex-grid]>[class*=_mdd-12] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-0] {
        margin-left: 0
    }
    [class*=GridLex-grid]>[push-left*=_mdd-1] {
        margin-left: 8.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-2] {
        margin-left: 16.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-3] {
        margin-left: 25%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-4] {
        margin-left: 33.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-5] {
        margin-left: 41.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-6] {
        margin-left: 50%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-7] {
        margin-left: 58.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-8] {
        margin-left: 66.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-9] {
        margin-left: 75%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-10] {
        margin-left: 83.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_mdd-11] {
        margin-left: 91.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-0] {
        margin-right: 0
    }
    [class*=GridLex-grid]>[push-right*=_mdd-1] {
        margin-right: 8.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-2] {
        margin-right: 16.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-3] {
        margin-right: 25%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-4] {
        margin-right: 33.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-5] {
        margin-right: 41.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-6] {
        margin-right: 50%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-7] {
        margin-right: 58.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-8] {
        margin-right: 66.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-9] {
        margin-right: 75%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-10] {
        margin-right: 83.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_mdd-11] {
        margin-right: 91.66666667%
    }
}

@media only screen and (max-width: 991px) {
    [class*=GridLex-grid]>[class*=_sm-1] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
    [class*=GridLex-grid]>[class*=_sm-2] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=GridLex-grid]>[class*=_sm-3] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=GridLex-grid]>[class*=_sm-4] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=GridLex-grid]>[class*=_sm-5] {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }
    [class*=GridLex-grid]>[class*=_sm-6] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=GridLex-grid]>[class*=_sm-7] {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }
    [class*=GridLex-grid]>[class*=_sm-8] {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }
    [class*=GridLex-grid]>[class*=_sm-9] {
        flex-basis: 75%;
        max-width: 75%
    }
    [class*=GridLex-grid]>[class*=_sm-10] {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }
    [class*=GridLex-grid]>[class*=_sm-11] {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }
    [class*=GridLex-grid]>[class*=_sm-12] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=GridLex-grid]>[push-left*=_sm-0] {
        margin-left: 0
    }
    [class*=GridLex-grid]>[push-left*=_sm-1] {
        margin-left: 8.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_sm-2] {
        margin-left: 16.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_sm-3] {
        margin-left: 25%
    }
    [class*=GridLex-grid]>[push-left*=_sm-4] {
        margin-left: 33.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_sm-5] {
        margin-left: 41.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_sm-6] {
        margin-left: 50%
    }
    [class*=GridLex-grid]>[push-left*=_sm-7] {
        margin-left: 58.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_sm-8] {
        margin-left: 66.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_sm-9] {
        margin-left: 75%
    }
    [class*=GridLex-grid]>[push-left*=_sm-10] {
        margin-left: 83.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_sm-11] {
        margin-left: 91.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_sm-0] {
        margin-right: 0
    }
    [class*=GridLex-grid]>[push-right*=_sm-1] {
        margin-right: 8.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_sm-2] {
        margin-right: 16.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_sm-3] {
        margin-right: 25%
    }
    [class*=GridLex-grid]>[push-right*=_sm-4] {
        margin-right: 33.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_sm-5] {
        margin-right: 41.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_sm-6] {
        margin-right: 50%
    }
    [class*=GridLex-grid]>[push-right*=_sm-7] {
        margin-right: 58.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_sm-8] {
        margin-right: 66.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_sm-9] {
        margin-right: 75%
    }
    [class*=GridLex-grid]>[push-right*=_sm-10] {
        margin-right: 83.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_sm-11] {
        margin-right: 91.66666667%
    }
}

@media only screen and (max-width: 767px) {
    [class*=GridLex-grid]>[class*=_xs-1] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
    [class*=GridLex-grid]>[class*=_xs-2] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=GridLex-grid]>[class*=_xs-3] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=GridLex-grid]>[class*=_xs-4] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=GridLex-grid]>[class*=_xs-5] {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }
    [class*=GridLex-grid]>[class*=_xs-6] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=GridLex-grid]>[class*=_xs-7] {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }
    [class*=GridLex-grid]>[class*=_xs-8] {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }
    [class*=GridLex-grid]>[class*=_xs-9] {
        flex-basis: 75%;
        max-width: 75%
    }
    [class*=GridLex-grid]>[class*=_xs-10] {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }
    [class*=GridLex-grid]>[class*=_xs-11] {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }
    [class*=GridLex-grid]>[class*=_xs-12] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=GridLex-grid]>[push-left*=_xs-0] {
        margin-left: 0
    }
    [class*=GridLex-grid]>[push-left*=_xs-1] {
        margin-left: 8.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xs-2] {
        margin-left: 16.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_xs-3] {
        margin-left: 25%
    }
    [class*=GridLex-grid]>[push-left*=_xs-4] {
        margin-left: 33.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xs-5] {
        margin-left: 41.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_xs-6] {
        margin-left: 50%
    }
    [class*=GridLex-grid]>[push-left*=_xs-7] {
        margin-left: 58.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xs-8] {
        margin-left: 66.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_xs-9] {
        margin-left: 75%
    }
    [class*=GridLex-grid]>[push-left*=_xs-10] {
        margin-left: 83.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xs-11] {
        margin-left: 91.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xs-0] {
        margin-right: 0
    }
    [class*=GridLex-grid]>[push-right*=_xs-1] {
        margin-right: 8.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xs-2] {
        margin-right: 16.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xs-3] {
        margin-right: 25%
    }
    [class*=GridLex-grid]>[push-right*=_xs-4] {
        margin-right: 33.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xs-5] {
        margin-right: 41.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xs-6] {
        margin-right: 50%
    }
    [class*=GridLex-grid]>[push-right*=_xs-7] {
        margin-right: 58.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xs-8] {
        margin-right: 66.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xs-9] {
        margin-right: 75%
    }
    [class*=GridLex-grid]>[push-right*=_xs-10] {
        margin-right: 83.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xs-11] {
        margin-right: 91.66666667%
    }
}

@media (max-width: 479px) {
    [class*=GridLex-grid]>[class*=_xss-1] {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }
    [class*=GridLex-grid]>[class*=_xss-2] {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }
    [class*=GridLex-grid]>[class*=_xss-3] {
        flex-basis: 25%;
        max-width: 25%
    }
    [class*=GridLex-grid]>[class*=_xss-4] {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }
    [class*=GridLex-grid]>[class*=_xss-5] {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }
    [class*=GridLex-grid]>[class*=_xss-6] {
        flex-basis: 50%;
        max-width: 50%
    }
    [class*=GridLex-grid]>[class*=_xss-7] {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }
    [class*=GridLex-grid]>[class*=_xss-8] {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }
    [class*=GridLex-grid]>[class*=_xss-9] {
        flex-basis: 75%;
        max-width: 75%
    }
    [class*=GridLex-grid]>[class*=_xss-10] {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }
    [class*=GridLex-grid]>[class*=_xss-11] {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }
    [class*=GridLex-grid]>[class*=_xss-12] {
        flex-basis: 100%;
        max-width: 100%
    }
    [class*=GridLex-grid]>[push-left*=_xss-0] {
        margin-left: 0
    }
    [class*=GridLex-grid]>[push-left*=_xss-1] {
        margin-left: 8.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xss-2] {
        margin-left: 16.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_xss-3] {
        margin-left: 25%
    }
    [class*=GridLex-grid]>[push-left*=_xss-4] {
        margin-left: 33.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xss-5] {
        margin-left: 41.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_xss-6] {
        margin-left: 50%
    }
    [class*=GridLex-grid]>[push-left*=_xss-7] {
        margin-left: 58.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xss-8] {
        margin-left: 66.66666667%
    }
    [class*=GridLex-grid]>[push-left*=_xss-9] {
        margin-left: 75%
    }
    [class*=GridLex-grid]>[push-left*=_xss-10] {
        margin-left: 83.33333333%
    }
    [class*=GridLex-grid]>[push-left*=_xss-11] {
        margin-left: 91.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xss-0] {
        margin-right: 0
    }
    [class*=GridLex-grid]>[push-right*=_xss-1] {
        margin-right: 8.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xss-2] {
        margin-right: 16.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xss-3] {
        margin-right: 25%
    }
    [class*=GridLex-grid]>[push-right*=_xss-4] {
        margin-right: 33.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xss-5] {
        margin-right: 41.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xss-6] {
        margin-right: 50%
    }
    [class*=GridLex-grid]>[push-right*=_xss-7] {
        margin-right: 58.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xss-8] {
        margin-right: 66.66666667%
    }
    [class*=GridLex-grid]>[push-right*=_xss-9] {
        margin-right: 75%
    }
    [class*=GridLex-grid]>[push-right*=_xss-10] {
        margin-right: 83.33333333%
    }
    [class*=GridLex-grid]>[push-right*=_xss-11] {
        margin-right: 91.66666667%
    }
}


/** Customized */

.GridLex-gap-20-wrappper {
    margin-left: -10px;
    margin-right: -10px;
}

.GridLex-gap-20-wrappper > div > div > div {
    margin-left: 10px;
    margin-right: 10px;
}

.GridLex-gap-30-wrappper {
    margin-left: -15px;
    margin-right: -15px;
}

.GridLex-gap-30-wrappper > div > div > div {
    margin-left: 15px;
    margin-right: 15px;
}


/**
 * Customized Flex Grid from FOundation
 * http://foundation.zurb.com/forum/posts/36234-flex-grid-in-css
 */

.flex-row {
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto
}

.flex-row .flex-row,
.flex-column-row .flex-row {
    margin-left: 0;
    margin-right: 0
}

.flex-column,
.flex-columns {
    flex: 1 1 0;
    padding-left: .0;
    padding-right: 0
}

.flex-small-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.flex-small-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.flex-small-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.flex-small-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.flex-small-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.flex-small-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.flex-small-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.flex-small-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.flex-small-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.flex-small-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.flex-small-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.flex-small-12 {
    flex: 0 0 100%;
    max-width: 100%
}

@media screen and (min-width: 32em) {
    .flex-medium-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-medium-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-medium-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-medium-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-medium-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-medium-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-medium-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-medium-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-medium-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-medium-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-medium-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-medium-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media screen and (min-width: 64em) {
    .flex-large-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-large-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-large-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-large-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-large-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-large-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-large-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-large-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-large-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-large-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-large-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-large-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media screen and (min-width: 32em) {
    .flex-medium-expand {
        flex: 1 1 0
    }
}

@media screen and (min-width: 64em) {
    .flex-large-expand {
        flex: 1 1 0
    }
}

.flex-shrink {
    flex: 0 0 auto
}

.flex-row.flex-medium-unstack .flex-column {
    flex: 0 0 100%
}

@media screen and (min-width: 32em) {
    .flex-row.flex-medium-unstack .flex-column {
        flex: 1 1 0
    }
}

.flex-row.flex-large-unstack .flex-column {
    flex: 0 0 100%
}

@media screen and (min-width: 64em) {
    .flex-row.flex-large-unstack .flex-column {
        flex: 1 1 0
    }
}

.flex-small-order-1 {
    order: 1
}

.flex-small-order-2 {
    order: 2
}

.flex-small-order-3 {
    order: 3
}

.flex-small-order-4 {
    order: 4
}

.flex-small-order-5 {
    order: 5
}

.flex-small-order-6 {
    order: 6
}

@media screen and (min-width: 32em) {
    .flex-medium-order-1 {
        order: 1
    }
    .flex-medium-order-2 {
        order: 2
    }
    .flex-medium-order-3 {
        order: 3
    }
    .flex-medium-order-4 {
        order: 4
    }
    .flex-medium-order-5 {
        order: 5
    }
    .flex-medium-order-6 {
        order: 6
    }
}

@media screen and (min-width: 64em) {
    .flex-large-order-1 {
        order: 1
    }
    .flex-large-order-2 {
        order: 2
    }
    .flex-large-order-3 {
        order: 3
    }
    .flex-large-order-4 {
        order: 4
    }
    .flex-large-order-5 {
        order: 5
    }
    .flex-large-order-6 {
        order: 6
    }
}

.flex-row.flex-align-right {
    justify-content: flex-end
}

.flex-row.flex-align-center {
    justify-content: center
}

.flex-row.flex-align-justify {
    justify-content: space-between
}

.flex-row.flex-align-spaced {
    justify-content: space-around
}

.flex-row.flex-align-top {
    align-items: flex-start
}

.flex-column.flex-align-top,
.flex-columns.flex-align-top {
    align-self: flex-start
}

.flex-row.flex-align-bottom {
    align-items: flex-end
}

.flex-column.flex-align-bottom,
.flex-columns.flex-align-bottom {
    align-self: flex-end
}

.flex-row.flex-align-middle {
    align-items: center
}

.flex-column.flex-align-middle,
.flex-columns.flex-align-middle {
    align-self: center
}

.flex-row.flex-align-stretch {
    align-items: stretch
}

.flex-column.flex-align-stretch,
.flex-columns.flex-align-stretch {
    align-self: stretch
}

.flex-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.flex-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.flex-md-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.flex-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.flex-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.flex-md-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.flex-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.flex-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.flex-md-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.flex-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.flex-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.flex-md-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.flex-gap-1 {
    margin-left: -1px;
    margin-right: 0;
}

.flex-gap-1 > .flex-columns,
.flex-gap-1 > .flex-column {
    padding-left: 1px;
    padding-right: 0;
}

.flex-gap-2 {
    margin-left: -1px;
    margin-right: -1px;
}

.flex-gap-2 > .flex-columns,
.flex-gap-2 > .flex-column {
    padding-left: 1px;
    padding-right: 1px;
}

.flex-gap-5 {
    margin-left: -3px;
    margin-right: -2px;
}

.flex-gap-5 > .flex-columns,
.flex-gap-5 > .flex-column {
    padding-left: 3px;
    padding-right: 2px;
}

.flex-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
}

.flex-gap-10 > .flex-columns,
.flex-gap-10 > .flex-column {
    padding-left: 5px;
    padding-right: 5px;
}

.flex-gap-15 {
    margin-left: -8px;
    margin-right: -7px;
}

.flex-gap-15 > .flex-columns,
.flex-gap-15 > .flex-column {
    padding-left: 8px;
    padding-right: 7px;
}

.flex-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
}

.flex-gap-20 > .flex-columns,
.flex-gap-20 > .flex-column {
    padding-left: 10px;
    padding-right: 10px;
}

.flex-gap-25 {
    margin-left: -13px;
    margin-right: -12px;
}

.flex-gap-25 > .flex-columns,
.flex-gap-25 > .flex-column {
    padding-left: 13px;
    padding-right: 12px;
}

.flex-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
}

.flex-gap-30 > .flex-columns,
.flex-gap-30 > .flex-column {
    padding-left: 15px;
    padding-right: 15px;
}

.flex-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
}

.flex-gap-40 > .flex-columns,
.flex-gap-40 > .flex-column {
    padding-left: 20px;
    padding-right: 20px;
}

.flex-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
}

.flex-gap-50 > .flex-columns,
.flex-gap-50 > .flex-column {
    padding-left: 25px;
    padding-right: 25px;
}

[class*="flex-gap-"] .flex-gap-1 {
    margin-left: -1px;
    margin-right: 0;
}

[class*="flex-gap-"] .flex-gap-2 {
    margin-left: -1px;
    margin-right: -1px;
}

[class*="flex-gap-"] .flex-gap-5 {
    margin-left: -3px;
    margin-right: -2px;
}

[class*="flex-gap-"] .flex-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
}

[class*="flex-gap-"] .flex-gap-15 {
    margin-left: -8px;
    margin-right: -7px;
}

[class*="flex-gap-"] .flex-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
}

[class*="flex-gap-"] .flex-gap-25 {
    margin-left: -13px;
    margin-right: -12px;
}

[class*="flex-gap-"] .flex-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
}

[class*="flex-gap-"] .flex-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
}

[class*="flex-gap-"] .flex-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
}

@media only screen and (min-width: 1200px) {
    .flex-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media only screen and (max-width: 1199px) {
    .flex-mdd-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-mdd-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-mdd-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-mdd-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-mdd-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-mdd-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-mdd-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-mdd-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-mdd-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-mdd-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-mdd-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-mdd-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media only screen and (max-width: 991px) {
    .flex-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media only screen and (max-width: 767px) {
    .flex-xs-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-xs-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-xs-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-xs-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-xs-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-xs-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-xs-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-xs-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-xs-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-xs-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-xs-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-xs-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (max-width: 479px) {
    .flex-xss-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .flex-xss-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .flex-xss-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .flex-xss-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .flex-xss-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .flex-xss-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .flex-xss-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .flex-xss-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .flex-xss-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .flex-xss-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .flex-xss-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .flex-xss-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}
